<template>
  <div>
    <div>
      <el-form :model="form"
               label-width="80px">
        <el-row :gutter="10"
                style="display: flex;flex-wrap: wrap;">

          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label="年级:">
              <el-select v-model="form.grade_id"
                         style="width:100%"
                         clearable
                         placeholder="选择年级">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label="科目:">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         clearable
                         placeholder="选择科目">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label="组卷类型:">
              <el-select v-model="form.module"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in moduleList"
                           :key="item.module_id"
                           :label="item.module_name"
                           :value="item.module_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label="开始时间:">
              <el-date-picker v-model="form.beginTime"
                              style="width:100%"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="开始时间" />
            </el-form-item>
          </el-col>
          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label="结束时间:">
              <el-date-picker v-model="form.endTime"
                              style="width:100%"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              default-time="23:59:59"
                              placeholder="截止时间" />
            </el-form-item>
          </el-col>

          <el-col :lg="6"
                  :md="12"
                  :sm="12"
                  :xl="6"
                  :xs="24">
            <el-form-item label-width="30px">
              <el-button type="primary"
                         @click="search">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="btn_wap">

      <el-button type="primary"
                 plain
                 @click="showDialog('3')">整卷出卷</el-button>
      <el-button type="primary"
                 plain
                 @click="showDialog('4')">考点组卷</el-button>
      <el-button type="primary"
                 plain
                 @click="showDialog('5')">章节组卷</el-button>
      <el-button type="primary"
                 plain
                 @click="showDialog('6')">模板组卷</el-button>
    </div>
    <MyDiaglog :title="title"
               :type="type"
               ref="paperRef" />
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <!-- -->
  </div>
</template>

<script>
import MyDiaglog from './components/paperDialog'
import { getGrade } from '@/api/grade.js'
import { getUserSubject } from '@/api/subject.js'
import { getSSOUrl } from '@/api/gd.js'
import schoolIdList from '@/utils/gdSchoolList.js'

export default {
  data () {
    return {
      title: '',
      type: '',
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      form: { subject_id: '' },
      gradeList: [],
      gradeList1: ['初三', '初二', '初一'],
      subjectList: [],
      moduleList: [
        {
          module_id: 3,
          module_name: '整卷出卷'
        },
        {
          module_id: 4,
          module_name: '考点组卷'
        },

        {
          module_id: 5,
          module_name: '章节组卷'
        },
        {
          module_id: 6,
          module_name: '模板组卷'
        }
      ],

      pageName: 'teacher_creatr_paper',
      tableColumns: [
        { prop: 'edu_paper_id', align: 'center', label: 'ID', width: '100' },
        { prop: 'paper_name', align: 'center', label: '试卷名称', width: '' },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '100' },
        { prop: 'module_desc', align: 'center', label: '类型', width: '' },
        { prop: 'grade_id', align: 'center', label: '年级', width: '', isStatus: true },
        { prop: 'create_time', align: 'center', label: '创建时间', width: '' },
        { prop: 'paper_score', align: 'center', label: '总分', width: '100' },

        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '收藏', event: 'collect', type: "collect", isChangeType: true },
            { content: '删除', type: 'del', event: 'delRow' },
             { content: '考试准备', type: 'exam', event: 'jumpGd', isBtnStatus: false, isPd: true },
            { content: '阅卷', type: 'preview', event: 'jumpGd2', isBtnStatus: false, isPd: true },
          ]
        },
      ]
    }
  },
  components: {
    MyDiaglog,
  },
  mounted () {
    this.getGradeList()
    this.setTablesColums()
    this.getSubjectList()

  },
  methods: {
       getBtnStatus (opItem, row) {
      if (opItem.event.indexOf("jumpGd") != -1) {
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
        if (Number(userInfo['is_admin']) == 1 || schoolIdList.find(value => value === Number(userInfo['edu_school_id']))) {
          return true
        } else {
          return false
        }
      }
    },
    async getGradeList () {
      const { data } = await getGrade()
      this.gradeList = data.list
    },
    async getSubjectList () {
      // this.form.questionSubject = ''

      const { data } = await getUserSubject()
      this.subjectList = data.list
    },

    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value, row) {
      return ''
    },
    getValue (prop, value, row) {
      if (prop == 'grade_id') {
        return this.gradeList1[Number(row['grade_id']) - 1]
      }
    },
       jumpGd () {
      getSSOUrl('sso').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },
    jumpGd2 () {
      getSSOUrl('review').then(res => {
        if (res.data) {
          window.open(res.data.url, '_blank')
        }
      })
    },
    getIconContent (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return '收藏'
        } else {
          return '取消收藏'
        }
      }
    },
    getIconType (opItem, row) {
      if (opItem.type == "collect") {
        if (row['is_collect'] == 0) {
          return 'collect'
        } else {
          return 'cancelCollect'
        }
      }
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v1/combination/paper_list',
        method: 'get',
        params: {
          page,
          limit,
          ...this.form
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showDialog (type) {
      this.type = type
      switch (type) {
        case '3': this.title = "新增整卷出卷"; break;
        case '4': this.title = "新增考点组卷"; break;
        case '5': this.title = "新增章节组卷"; break;
        case '6': this.title = "新增模板组卷"; break;
      }
      this.$refs.paperRef.dialogVisible = true
      if (this.type == 6) {
        this.$refs.paperRef.width = "400px"
      } else {
        this.$refs.paperRef.width = "800px"
      }

      this.$nextTick(() => {
        this.$refs.paperRef.initData()
      })
    },
    toInfo (row) {
      this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + row.edu_paper_id + '&module=' + row.module)
    },
    collect (row) {
      this.$http({
        url: '/api/v1/combination/collect',
        method: 'post',
        data: {
          edu_paper_id: row.edu_paper_id
        }
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    delRow (row) {

      let url = '/api/v1/combination/del_edu_paper'
      let data = {
        edu_paper_id: row.edu_paper_id
      }

      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: url,
          method: 'post',
          data
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.refresh()
        })
      })

    },
    search () { this.$refs.pagination.toFirstPage() },
    refresh () {
      this.$refs.pagination.shuaxin()
    }
  },


}
</script>

<style lang="scss" scoped>
.btn_wap {
  margin-bottom: 15px;
}
</style>